import * as React from "react"

import defaults from "../../components/dafaults"

// components
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Search from "../../components/Search/index"
import Loading from "../../components/shared/Loading"

// styles
import "../../styles/css/styles.css"

const ListSearch = ({ location }) => {

    const [ listResults, setListResult ] = React.useState(null)
    const [ size, setSize ] = React.useState(null)
    const [ urlVeiculo , setUrlVeiculo ] = React.useState({});

    React.useEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
    })

    const correctNames = {
        JsonApl: {
            title: "Veículos",
            order: 2,
            columns: [
                "Linha",
                "Categoria",
                "Modelo",
                "Montadora",
                "Descrição do Modelo",
                "Motor",
                "Ano"
            ],
            values: [
                "Linha",
                "Categoria",
                "Modelo",
                "Montadora",
                "DescModelo",
                "Motor",
                "Ano"
            ]
        },
        JsonConv: {
            title: "Conversões",
            order: 3,
            columns: [
                "Código Wega",
                "Código Concorrente",
                "Marca"
            ],
            values: [
                "CodigoComercialWega",
                "CodigoConcorrente",
                "Marca"
            ]
        },
        JsonDT: {
            title: "Produtos",
            order: 1,
            columns: [
                "Código Wega",
                "Tipo de Filtro",
                "Comprimento",
                "Largura",
                "Altura",
                "Diâmetro Externo",
                "Diâmetro Interno"
            ],
            values: [
                "CodigoComercialWega",
                "DescTec",
                "Comprimento",
                "Largura",
                "Altura",
                "DiametroExt",
                "DiametroInt"
            ]
        }
    }
    
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch(`${defaults.api.base}search${location.search}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(listResults == null)
                setListResult(result.Obj)
        })
        .catch(error => console.log('error', error));

    return(
        listResults == null ?
            <Loading />
        :
        <>
            <Header />
            <div className="searchList">
                <div className="container">
                    <h1>RESULTADO DE BUSCA</h1>
                    {
                        listResults &&
                            Object.keys(listResults).map(type => (
                                listResults[type].length > 0 &&
                                    <div className="full" style={{order: correctNames[type].order }}>
                                        <h2>{ correctNames[type].title }</h2>
                                        <div className="list">
                                            {
                                                size > 991 &&
                                                <div className="thead">
                                                    {
                                                        correctNames[type].columns.map(column => (
                                                            <div>
                                                                { column }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            {
                                                listResults[type].map(list => (
                                                    <div className="tbody">
                                                        {
                                                            size < 991 &&
                                                            <ul className="thead">
                                                            {
                                                                correctNames[type].columns.map(column => (
                                                                    <div>
                                                                        { column }
                                                                    </div>
                                                                ))
                                                            }
                                                            </ul>
                                                        }
                                                        <ul className="content">
                                                        {
                                                            correctNames[type].values.map(value => (
                                                                value == "Modelo" ?
                                                                    <a href={`/busca/veiculo?CodModelo=${list["CodModelo"]}&ano=${list["Ano"]}&motor=${list['Motor']}&linha=${list['Linha']}&categoria=${list['Categoria']}&descmodelo=${list['DescModelo']}`}>
                                                                        { list[value] }
                                                                    </a>
                                                                :
                                                                    value == "CodigoComercialWega" ?
                                                                        <a href={`/busca/produto?cod=${list[value]}`}>
                                                                            { list[value] }
                                                                        </a>
                                                                    :
                                                                        <div>
                                                                            { list[value] }
                                                                        </div>
                                                            ))
                                                        }
                                                        </ul>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                            ))
                    }
                </div>
            </div>
            <Search internal={true} />
            <Footer />
        </>

    )
}

export default ListSearch